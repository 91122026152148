// import apiClient from "../services/api-client";
import { APIClient } from "../services/apiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Response from "../response/response";
import ErrorResponse from "../response/error";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export type firstProps = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  stateId: number;
  lgaId: number;
  token: string;
  address: string;
  gender: string;
};
export type secondStep = {
  store_name: string;
  store_description: string;
  store_category: number;
  banner: string;
};

export type Props = firstProps & secondStep;
export interface ApiResponse {
  success: boolean;
  message: {
    token: string;
  } | null;
  errors: null;
}

const response = new Response();
const errorResponse = new ErrorResponse();
export class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}

const useCreateSul = (config: any) => {
  const axiosPrivate = useAxiosPrivate();
  const apiClient = new APIClient<ApiResponse, Props>("/snl/create", axiosPrivate);
  return useMutation<ApiResponse, RequestError, Props>(
    (newData: Props) => apiClient.post(newData, config),
    {
      onSuccess: (data, variable) => {
        // response.registerResponse(data, variable);
      },
      onError: (error) => {
        if (error.response) {
          console.log("An error occurred:", error.response.data.message);
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          toast.error(error.response.data.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });

          // errorResponse.registerError(error.response.data.message);
        }
      },
    }
  );
};
export default useCreateSul;
