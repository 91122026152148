import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext'; 

export default function useAuth() {
  const { auth, setAuth } = useContext(AuthContext);
  return {
    auth,
    setAuth
  };
  // return
}
