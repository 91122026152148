// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #4ca30d;
  --secondary-color: #667085;
  --font-inter: "Inter", sans-serif;
  --ui-gray: #f9fafb;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 3.5em !important;
}

.asterisk::after {
  content: " *";
  color: red;
}

.storybook-tagline-flexline-container {
  background: none !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.scss"],"names":[],"mappings":"AACA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;AACF;;AAEA;EACE,wBAAA;EACA,0BAAA;EACA,iCAAA;EACA,kBAAA;AACF;;AAGA;EACE,sBAAA;EACA,wBAAA;AAAF;;AAGA;EACE,aAAA;EACA,UAAA;AAAF;;AAEA;EACE,2BAAA;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap\");\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n:root {\n  --primary-color: #4ca30d;\n  --secondary-color: #667085;\n  --font-inter: \"Inter\", sans-serif;\n  --ui-gray: #f9fafb;\n}\n\n\n.react-tel-input .form-control  {\n  width: 100% !important;\n  height: 3.5em !important;\n}\n\n.asterisk::after {\n  content:' *';\n  color: red\n}\n.storybook-tagline-flexline-container{\n  background: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
