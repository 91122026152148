import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { authContext } from '../context';
import { IRootReducerState } from '../redux/IRootReducer';
import { INITIALIZE, LOG_OUT } from "../redux/auth/auth.action";
import eventBus from '../utils/event';
import useAuth from '../hooks/useAuth';
import Cookies from 'js-cookie';

const redirectUrl = 'https://store.gogeeper.com/dashboard/get-started';
// export const authBaseUrl = 'https://auth.gogeeper.com/oauth/authorize';
export const authBaseUrl = 'http://localhost:3000/oauth/authorize';
const rolesToRedirect = ['Farmer', 'AgricExpert'];

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch();
  const newAuth = useAuth(); // Using custom hook for auth state
  const { isAuthenticated, user } = useSelector((state: IRootReducerState) => state.auth);

  const loginUrl = `${authBaseUrl}/login/fromUmlAssign/Marketplace/initiator_id/${nanoid(35)}?mainAppOrigin=${encodeURIComponent(window.location.origin)}`;
  const signupUrl = `${authBaseUrl}/onboarding/fromUmlAssign/Marketplace/initiator_id/${nanoid(35)}?mainAppOrigin=${encodeURIComponent(window.location.origin)}`;

  const openPopup = (url: string, eventName: string, title: string, width = 1000, height = 700) => {
    const dualScreenLeft = window.screenLeft || window.screenX;
    const dualScreenTop = window.screenTop || window.screenY;

    const systemZoom = window.innerWidth / window.screen.availWidth;
    const left = (window.innerWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (window.innerHeight - height) / 2 / systemZoom + dualScreenTop;

    const popup = window.open(
      url,
      title,
      `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );

    if (popup) {
      popup.focus();
      eventBus.emit(eventName, { popup });
    }
  };

  const triggerLogin = () => openPopup(loginUrl, 'loginAuthTriggerEvt', 'gogeeper--login');
  const triggerSignUp = () => openPopup(signupUrl, 'signupAuthTriggerEvt', 'gogeeper--signup');

  const triggerLogout = () => {
    newAuth?.setAuth({ accessToken: '' }); // Clear the auth context
    dispatch(LOG_OUT()); // Dispatch Redux action to reset state
    Cookies.remove('accessToken');
  };

  useEffect(() => {
    if (newAuth?.auth.accessToken && !isAuthenticated) {
      dispatch(INITIALIZE(newAuth?.auth.accessToken));
    }
  }, [newAuth?.auth.accessToken, isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated && rolesToRedirect.includes(user?.role)) {
      window.location.href = redirectUrl;
    } else if (isAuthenticated) {
      window.location.href = 'https://marketplace.gogeeper.com/';
    }
  }, [isAuthenticated, user]);

  return (
    <authContext.Provider value={{ triggerLogin, triggerSignUp, triggerLogout }}>
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
