import { useQuery } from "@tanstack/react-query";
import { APIClient } from "../services/apiClient";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

interface SubCategory {
  id: number;
  name: string;
  parentId: number;
  image: string | null;
  featured: boolean;
}

interface Category {
  id: number;
  name: string;
  parentId: number | null;
  image: string | null;
  featured: boolean;
  subCategory?: SubCategory[]; // Optional if subCategory is not always present
}
interface Props {
  success: boolean;
  message: string;
  errors: null;
  data: Category[];
}
// Set the token for endpoints that require it

const useGetCategories = () => {
  const axiosPrivate = useAxiosPrivate();
  const apiClient = new APIClient<Props>(`/categories`, axiosPrivate);

  // Use the useQuery hook from react-query to fetch data
  return useQuery<Props, Error>({
    queryKey: ["get_categories"],
    queryFn: apiClient.get,
  });
};
export default useGetCategories;
