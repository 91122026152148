import { useQuery } from "@tanstack/react-query";
import { APIClient } from "../services/apiClient";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

interface State {
  id: number;
  name: string;
  country_id: number;
  createdAt: string;
  updatedAt: string;
}
interface StatesProps {
  success: boolean;
  message: string;
  errors: null;
  data: State[];
}
// Set the token for endpoints that require it

const useStates = (params: number) => {
  const axiosPrivate = useAxiosPrivate();
  const apiClient = new APIClient<StatesProps>(`/states/${params}`, axiosPrivate);

  // Use the useQuery hook from react-query to fetch data
  return useQuery<StatesProps, Error>({
    queryKey: ["states"],
    queryFn: apiClient.get,
  });
};
export default useStates;
